import { graphql, Link } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

import LogoWithLabel from "../components/LogoWithLabel";
import { WEBSITE_TITLE, WEBSITE_URL } from "../constants";
import { meta } from "../meta";
import * as styles from "./404.css";

export const query = graphql`
  query Page404 {
    ogImage: file(relativePath: { eq: "og.png" }) {
      childImageSharp {
        original {
          src
          width
          height
        }
      }
    }
  }
`;

interface Page404Props {
  data: GatsbyTypes.Page404Query;
}
const Page404: React.FC<Page404Props> = (props) => {
  const title = WEBSITE_TITLE;
  const description = "해당 페이지를 찾을 수 없어요";

  return (
    <div className={styles.container}>
      <Helmet
        htmlAttributes={{
          lang: "ko",
        }}
        title={title}
        meta={[
          ...meta({
            url: WEBSITE_URL,
            title,
            description,
            thumbnail: {
              url: props.data.ogImage?.childImageSharp?.original?.src,
              width: props.data.ogImage?.childImageSharp?.original?.width,
              height: props.data.ogImage?.childImageSharp?.original?.height,
            },
          }),
        ]}
      />
      <div className={styles.main}>
        <div className={styles.centered}>
          <h1 className={styles.heading}>404</h1>
          <p className={styles.caption}>{description}</p>
          <Link to="/" className={styles.button}>
            홈으로 돌아가기
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Page404;
